<template>
  <div class="praticeCon clearfix">
    <div class="praticeConfl fl">
      <ul class="praticeConfloul clearfix">
        <li
          v-for="(item, index) in praticeConfloulist"
          :key="index"
          :class="{
            aa1: item.area_type == 1,
            aa2: item.area_type == 2,
            aa3: item.area_type == 3,
            on: selectindex == item.area_type,
          }"
          @click="praticeConfloulqh(item)"
        >
          <div
            :style="{ backgroundImage: item.img1 }"
            class="praticeConfloulimg1"
          />
          <div
            :style="{ backgroundImage: item.img2 }"
            class="praticeConfloulimg2"
          />
          <span>{{ item.title }}</span>
        </li>
        <li
          v-for="(item, index) in praticeConfloulist1"
          :key="'bb' + index"
          :class="{
            aa4: item.area_type == 4,
            aa5: item.area_type == 5,
            on: selectindex == item.area_type,
          }"
          @click="praticeConfloulqh(item)"
        >
          <div
            :style="{ backgroundImage: item.img1 }"
            class="praticeConfloulimg1"
          />
          <div
            :style="{ backgroundImage: item.img2 }"
            class="praticeConfloulimg2"
          />
          <span>{{ item.title }}</span>
        </li>
      </ul>
      <!--考点刷题  -->
      <div v-show="selectindex == 1">
        <div class="praticeConflcon">
          <div class="praticeConflcond1">
            <div class="praticeConflcontit">
              <div class="praticeConflcontitfl">
                <p>考点</p>
              </div>
              <div class="praticeConflcontitfr">
                <p>已做/总数</p>
                <p>正确率</p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
          <div class="praticeHomeBrushQuestionsbody">
            <ul class="praticeHomeBrushQuestionsbodyoul">
              <li v-for="(item, index) in listData" :key="index">
                <div
                  class="praticeHomeBrushQuestionsbodyoulfirst clearfix"
                  :class="{
                    show: item.showFlag,
                  }"
                  @click="toZuoye(item)"
                >
                  <div class="clearfix praticeHomeBrushQuestionsbodyoulfirstfl">
                    <i
                      class="iconfont fl"
                      :class="{
                        'icon-first': item.haschild == 1,
                      }"
                      @click.stop="firstClick(item)"
                    ></i>
                    <span class="fl">{{ item.catalogue_name }}</span>
                  </div>
                  <div class="praticeHomeBrushQuestionsbodyoulfirstfr">
                    <span>{{ item.show_sum }}/{{ item.question_count }}</span>
                    <span>{{ item.accuracy }}%</span>
                    <p>
                      <em v-if="item.answer_id > 0">继续刷题</em>
                      <!-- <em v-if="item.answer_id ==-1">上次作答</em> -->
                      <i class="iconfont icon-study_xz"></i>
                    </p>
                  </div>
                </div>
                <template v-if="item.haschild == 1">
                  <div v-show="item.showFlag">
                    <div
                      class="praticeHomeBrushQuestionsbodyoulsecond"
                      v-for="(ite, inde) in item.child"
                      :key="inde"
                    >
                      <div
                        class="
                          praticeHomeBrushQuestionsbodyoulsecondlist
                          clearfix
                        "
                        :class="{
                          show: ite.showFlagChild,
                        }"
                        @click="toZuoye(ite)"
                      >
                        <div
                          class="
                            clearfix
                            praticeHomeBrushQuestionsbodyoulfirstfl
                          "
                        >
                          <i
                            class="iconfont fl"
                            :class="{ 'icon-second': ite.haschild == 1 }"
                            @click.stop="secondClick(ite)"
                          ></i>
                          <span class="fl">{{ ite.catalogue_name }}</span>
                        </div>
                        <div class="praticeHomeBrushQuestionsbodyoulfirstfr">
                          <span
                            >{{ ite.show_sum }}/{{ ite.question_count }}</span
                          >
                          <span>{{ ite.accuracy }}%</span>
                          <p>
                            <em v-if="ite.answer_id > 0">继续刷题</em>
                            <em v-if="ite.answer_id ==-1">上次作答</em>
                            <i class="iconfont icon-study_xz"></i>
                          </p>
                        </div>
                      </div>
                      <template v-if="ite.haschild == 1">
                        <div v-show="ite.showFlagChild">
                          <div
                            class="praticeHomeBrushQuestionsbodyoulthird"
                            v-for="(it, ind) in ite.child"
                            :key="ind"
                          >
                            <div
                              class="
                                praticeHomeBrushQuestionsbodyoulthirdlist
                                clearfix
                              "
                              @click="toZuoye(it)"
                            >
                              <div
                                class="
                                  clearfix
                                  praticeHomeBrushQuestionsbodyoulfirstfl
                                "
                              >
                                <i class="fl"></i>
                                <span class="fl">{{ it.catalogue_name }}</span>
                              </div>
                              <div
                                class="praticeHomeBrushQuestionsbodyoulfirstfr"
                              >
                                <span
                                  >{{ it.show_sum }}/{{
                                    it.question_count
                                  }}</span
                                >
                                <span>{{ it.accuracy }}%</span>
                                <p>
                                  <em v-if="it.answer_id > 0">继续刷题</em>
                                  <em v-if="it.answer_id ==-1">上次作答</em>
                                  <i class="iconfont icon-study_xz"></i>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </li>
            </ul>
          </div>
        </div>
        <span class="praticeConflconst" @click="zdySt()"
          ><i class="iconfont icon-uxb_zdyst"></i>自定义刷题</span
        >
      </div>
      <!--试卷练习-->
      <div v-show="selectindex == 2" class="praticeConflsjlx">
        <ul class="searchsjoul">
          <li v-for="(item, index) in sjlistlb" :key="index">
            <div class="searchsjould clearfix">
              <div class="searchsjouldiv">
                <p class="searchsjouldivp">
                  {{ item.title }}
                </p>
                <div class="searchsjouldivdisp">
                  <span>共{{ item.question_num }}题</span>
                  <i>|</i>
                  <span>{{ item.answer_count }}人作答</span>
                  <i v-if="item.mark != ''">|</i>
                  <span>{{ item.mark }}</span>
                </div>
              </div>
              <div class="searchsjouldfr">
                <span @click="collect(item)"
                  ><i class="iconfont" :class="item.collect_id == 0?'icon-kcpj':'icon-qxsc'"></i
                  >{{ item.collect_id == 0 ? "收藏" : "取消收藏" }}</span
                >
                <template v-if="item.answer_id == 0">
                  <p @click="tozd(item)">开始作答</p>
                </template>
                <template v-else>
                  <p @click="tozd(item)">
                    {{ item.answer_staus == 0 ? "继续作答" : "查看报告" }}
                  </p>
                </template>
              </div>
            </div>
          </li>
        </ul>
        <div class="studyLogdivmore" @click="studyLogdivmore()">
          <span> <i class="iconfont icon-more_btn"></i>{{ btnMessage1 }} </span>
        </div>
      </div>
      <!--模拟考试-->
      <div v-show="selectindex == 3" class="praticeConflmnks">
        <ul class="searchsjoul">
          <li v-for="(item, index) in mklistlb" :key="index">
            <div class="searchsjould clearfix">
              <div class="searchsjouldiv">
                <p class="searchsjouldivp">
                  {{ item.exam_name }}
                </p>
                <div class="searchsjouldivdisp">
                  <span
                    >考试时间：{{
                      item.exam_start_time | formatimes("yyyy/MM/dd hh:mm")
                    }}
                    -
                    {{ item.exam_end_time | formatimes("hh:mm") }}</span
                  >
                  <i>|</i>
                  <span>共{{ item.question_num }}题</span>
                  <i>|</i>
                  <span>{{ item.stu_count }}人报名</span>
                </div>
              </div>
              <div class="searchsjouldfr">
                <el-button class='p' size="medium" round :disabled='item.exam_deadline&&nowtime>item.exam_deadline' v-if="item.sign_status == 0" @click="examsign(item)">
                  立即报名
                </el-button>
                 <el-button class='p' size="medium" round :disabled='item.exam_deadline&&nowtime>item.exam_deadline'
                  v-if="item.sign_status == 1 && item.answer_id == 0"
                  @click="examsigntozd(item)"
                >
                  进入考场
                </el-button>
                <el-button class='p' size="medium" round
                  v-if="
                    item.sign_status > 0 &&
                    item.answer_id != 0 &&
                    item.answer_staus == 0
                  "
                  @click="examsigntozd(item)"
                >
                  回到考场
               </el-button>
                <el-button class='p' size="medium" round :disabled='item.exam_deadline&&nowtime>item.exam_deadline'
                  v-if="item.sign_status == 2 && item.answer_id == 0"
                  @click="examsigntozd(item)"
                >
                  开始补考
                </el-button>
                <p v-if="item.answer_staus == 1" @click="examsigntobg(item)">
                  查看报告
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div class="studyLogdivmore" @click="examLogdivmore()">
          <span> <i class="iconfont icon-more_btn"></i>{{ btnMessage2 }} </span>
        </div>
      </div>
      <!--弱项提升-->
      <div v-show="selectindex == 4" class="praticeConflrxts">
        <ul class="praticeConflrxtsoul">
          <li
            v-for="(item, index) in rxlistlb"
            :key="index"
            @click="rxtozd(item)"
          >
            <div class="praticeConflrxtsfl">
              <span>{{ item.name }}</span>
            </div>
            <div class="praticeConflrxtsfr clearfix">
              <!--              <p>真题汇总/阅读理解与表达</p>-->
              <span
                >错误率 {{ item.wrongrank }}%<i class="iconfont icon-study_xz"></i
              ></span>
            </div>
          </li>
        </ul>
        <div class="studyLogdivmore" @click="weakspotlistmore()">
          <span> <i class="iconfont icon-more_btn"></i>{{ btnMessage3 }} </span>
        </div>
      </div>
      <!--评估报告-->
      <div v-show="selectindex == 5" class="praticeConflpgbg">
        <div class="praticeConflpgbgdiv1">
          <div class="praticeConflpgbgtit clearfix">
            <h6>练习情况</h6>
          </div>
          <ul class="praticeConflpgbgdiv1oul">
            <li>
              <h6>
                <span>{{ bgobj.rate }}</span
                >%
              </h6>
              <p><img src="../assets/images/tiku_cbg9.png" alt="" />正确率</p>
            </li>
            <li>
              <h6>
                <span>{{ bgobj.answer_paper_num }}</span
                >套
              </h6>
              <p>
                <img src="../assets/images/tiku_cbg10.png" alt="" />累计做卷
              </p>
            </li>
            <li>
              <h6>
                <span>{{ bgobj.answer_wrong }}</span
                >道
              </h6>
              <p><img src="../assets/images/tiku_cbg11.png" alt="" />错题数</p>
            </li>
            <li>
              <h6>
                <span>{{ bgobj.collect_num }}</span
                >道
              </h6>
              <p>
                <img src="../assets/images/tiku_cbg12.png" alt="" />收藏试题
              </p>
            </li>
          </ul>
        </div>
        <div class="praticeConflpgbgdiv1">
          <div class="praticeConflpgbgtit clearfix">
            <h6>能力对比</h6>
            <div class="MathAbilitytitfr fr">
              <span class="MathAbilitynldbpsp1">我的</span>
              <span class="MathAbilitynldbpsp2">全校考生</span>
            </div>
          </div>
          <div class="MathAbilitynldboul">
            <div
              class="MathAbilitynldbouloli clearfix"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="clearfix MathAbilitynldboulolidiv1">
                <span>&nbsp;</span>
                <div>
                  <h6 class="fontSize16 lineH20">{{ item.knowledge_name }}</h6>
                  <p>
                    <span
                      :style="{ width: item.user_rate + '%' }"
                      :class="{
                        on: item.user_rate <= item.total_rate,
                        tit: item.user_rate < 10,
                      }"
                    >
                      <i class="fontSize14">{{ item.user_rate }}</i>
                    </span>
                    <span
                      :style="{ width: item.total_rate + '%' }"
                      :class="{ tit: item.total_rate < 10 }"
                    >
                      <i class="fontSize14">{{ item.total_rate }}</i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="praticeConflpgbgdiv1" style="padding: 30px 0">
          <div class="praticeConflpgbgtit clearfix" style="padding: 0 24px">
            <h6>练习题量<span>（最近7天）</span></h6>
          </div>
          <div>
            <div
              class="MathAbilitydivycfd"
              id="MathAbilitydivycf"
              :style="{ width: '100%', height: '300px' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="praticeConfr fr">
      <ul class="praticeConfroul1">
        <li>
          <h6>{{ hometj.today_num }}</h6>
          <p>今日做题</p>
        </li>
        <li>
          <h6>{{ hometj.total_num }}</h6>
          <p>累计答题</p>
        </li>
        <li>
          <h6>{{ hometj.day_num }}</h6>
          <p>累计天数</p>
        </li>
      </ul>
      <ul class="praticeConfroul2">
        <li
          v-for="(item, index) in listkemu"
          :key="index"
          @click="jumpType(index)"
        >
          <h6><img :src="item.img" alt="" /></h6>
          <p>{{ item.tit }}</p>
        </li>
      </ul>
      <div class="praticeConfrhistory">
        <div class="praticeConfrhistorytit clearfix">
          <h6>练习历史</h6>
          <span @click="jumpType(3)"
            >查看全部<i class="iconfont icon-study_xz"></i
          ></span>
        </div>
        <ul class="praticeConfrhistoryoul" v-if="historyList.length>0">
          <li
            v-for="(item, index) in historyList"
            :key="index"
            @click="toHistory(item)"
          >
            <h6>{{ item.title }}</h6>
            <div class="clearfix praticeConfrhistoryouldiv">
              <div>
                <span>{{ item.endtime }}</span>
                <span v-if="item.status == 1"
                  >答对{{ item.right_num }}/{{ item.total_num }}</span
                >
                <p v-if="item.mark != ''">
                  <i class="iconfont icon-uxb_time"></i>{{ item.mark }}
                </p>
              </div>
              <em>
                {{ item.status == 1 ? "查看报告" : "继续作答"
                }}<i class="iconfont icon-study_xz"></i>
              </em>
            </div>
          </li>
        </ul>
        <div class="praticeConfrhistoryblank" v-else>
           <img src="../assets/images/blank.png" alt="暂无练习历史" />
           <p>暂无练习历史</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import {
  special,
  catalogue,
  sjlist,
  updatecollect,
  delcollect,
  home,
  exam,
  weakspotlist,
  datareport,
  answerlist,
  examsign,
} from "../assets/api";
import {sample} from '../../pape_do/assets/api'
export default {
  name: "praticeCon",
  data() {
    return {
      nowtime:'',
      selectindex: 0,
      praticeConfloulist: [],
      praticeConfloulist2: [
        {
          area_type: 1,
          title: "",
          img1: "url(" + require("../assets/images/tiku_cbg1.png") + ")",
          img2: "url(" + require("../assets/images/tiku_cbg1select.png") + ")",
        },
        {
          area_type: 2,
          title: "",
          img1: "url(" + require("../assets/images/tiku_cbg2.png") + ")",
          img2: "url(" + require("../assets/images/tiku_cbg2select.png") + ")",
        },
        {
          area_type: 3,
          title: "",
          img1: "url(" + require("../assets/images/tiku_cbg3.png") + ")",
          img2: "url(" + require("../assets/images/tiku_cbg3select.png") + ")",
        },
      ],
      praticeConfloulist1: [
        {
          area_type: 4,
          title: "弱项提升",
          img1: "url(" + require("../assets/images/tiku_cbg4.png") + ")",
          img2: "url(" + require("../assets/images/tiku_cbg4select.png") + ")",
        },
        {
          area_type: 5,
          title: "评估报告",
          img1: "url(" + require("../assets/images/tiku_cbg5.png") + ")",
          img2: "url(" + require("../assets/images/tiku_cbg5select.png") + ")",
        },
      ],
      listData: [],
      listkemu: [
        {
          tit: "我的错题",
          img: require("../assets/images/tiku_cbg6.png"),
        },
        {
          tit: "试题收藏",
          img: require("../assets/images/tiku_cbg7.png"),
        },
        {
          tit: "试卷收藏",
          img: require("../assets/images/tiku_cbg8.png"),
        },
      ],
      list: [],
      sjpage: 1,
      sjlistlb: [],
      mkpage: 1,
      mklistlb: [],
      rxpage: 1,
      rxlistlb: [],
      special_id: "",
      hometj: {},
      bgobj: {},
      historyList: [],
      btnMessage1: "加载更多",
      btnMessage2: "加载更多",
      btnMessage3: "加载更多",
    };
  },
  mounted() {
    let time=new Date();
    this.nowtime = time.getTime()/1000
    console.log(this.nowtime,77777)
    this.home();
    if (sessionStorage.getItem("column_id") != null) {
      this.special();
      this.answerlist();
    }
  },
  methods: {
    //首页统计
    home() {
      home().then((res) => {
        if (res.code == 0) {
          this.hometj = res.data;
        }
      });
    },
    answerlist() {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        page: 1,
        limit: 2,
      };
      answerlist(params).then((res) => {
        if (res.code == 0) {
          this.historyList = res.data;
        }
      });
    },
    //收藏、取消收藏
    collect(data) {
      if (data.collect_id == 0) {
        this.updatecollect(data);
      } else {
        this.delcollect(data);
      }
    },
    updatecollect(da) {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        collecttype: 2,
        question_paper_id: da.paper_id,
      };
      updatecollect(params).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "收藏成功",
            type: "success",
          });
          this.$set(da, "collect_id", res.data.collect_id);
        } else {
          this.$message.error("收藏失败");
        }
      });
    },
    delcollect(da) {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        collecttype: 2,
        collect_id: da.collect_id,
      };
      delcollect(params).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "取消收藏成功",
            type: "success",
          });
          this.$set(da, "collect_id", 0);
        } else {
          this.$message.error("取消收藏失败");
        }
      });
    },
    //试卷练习
    studyLogdivmore() {
      if (this.btnMessage1 == "加载更多") {
        this.sjpage++;
        this.sjlist(this.special_id);
      }
    },
    sjlist(da) {
      let data = {
        column_id: sessionStorage.getItem("column_id"),
        special_id: da,
        page: this.sjpage,
      };
      sjlist(data).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            if (this.sjlistlb.length == 0) {
              this.sjlistlb = res.data;
            } else {
              this.sjlistlb = this.sjlistlb.concat(res.data);
            }
          } else {
            this.btnMessage1 = "暂无更多";
          }
        }
      });
    },
    //考点专区
    catalogue(da) {
      let data = {
        special_id: da,
      };
      catalogue(data).then((res) => {
        if (res.code == 0) {
          this.listData = res.data;
          //默认展开
          this.listData.forEach((item) => {
            if (item.haschild == 1) {
              this.$set(item, "showFlag", false);
              item.child.forEach((ite) => {
                if (ite.haschild == 1) {
                  this.$set(ite, "showFlagChild", false);
                }
              });
            }
          });
        }
      });
    },
    //模拟考试
    exam(da) {
      let data = {
        special_id: da,
        page: this.mkpage,
      };
      exam(data).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            if (this.mklistlb.length == 0) {
              this.mklistlb = res.data;
            } else {
              this.mklistlb = this.mklistlb.concat(res.data);
            }
          } else {
            this.btnMessage2 = "暂无更多";
          }
        }
      });
    },
    examLogdivmore() {
      if (this.btnMessage2 == "加载更多") {
        this.mkpage++;
        this.exam(this.special_id);
      }
    },
    examsign(da) {
      let data = {
        special_id: da.special_id,
        exam_id: da.exam_id,
      };
      examsign(data).then((res) => {
        if (res.code == 0) {
          da.sign_status = res.data.sign_status;
          this.$message({
            message: "报名成功",
            type: "success",
          });
        }
      });
    },
    examsigntozd(data) {
      if(data.paper_id==0){
        this.$message.error('暂无试卷')
      }else{
      this.$router.push({
        path: "/pape_do",
        query: {
          answer_id: data.answer_id,
          paper_id: data.paper_id,
          area_type: this.selectindex,
          exam_id: data.exam_id,
          special_id:data.special_id
        },
      });}
    },
    examsigntobg(data) {
      this.$router.push({
        path: "/paper_report",
        query: {
          answer_id: data.answer_id,
          paper_id: data.paper_id,
          area_type: this.selectindex,
        },
      });
    },
    //弱项提升专区
    weakspotlist() {
      let data = {
        column_id: sessionStorage.getItem("column_id"),
        page: this.rxpage,
      };
      weakspotlist(data).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            if (this.rxlistlb.length == 0) {
              this.rxlistlb = res.data;
            } else {
              this.rxlistlb = this.rxlistlb.concat(res.data);
            }
          } else {
            this.btnMessage3 = "暂无更多";
            this.$message({
              message: "暂无更多",
              type: "warning",
            });
          }
        }
      });
    },
    weakspotlistmore() {
      if (this.btnMessage3 == "加载更多") {
        this.rxpage++;
        this.weakspotlist();
      }
    },
    rxtozd(data) {
      this.$router.push({
        path: "/pape_do",
        query: {
          subject_id: data.subject_id,
          answer_id: 0,
          area_type: this.selectindex,
        },
      });
    },
    //栏目下的专区
    special() {
      let data = {
        column_id: sessionStorage.getItem("column_id"),
      };
      special(data).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            res.data.forEach((item1) => {
              this.praticeConfloulist2.forEach((item) => {
                if (item.area_type == item1.area_type) {
                  item.title = item1.title;
                  this.$set(item, "special_id", item1.special_id);
                  this.praticeConfloulist.push(item);
                }
              });
            });
            this.selectindex = res.data[0].area_type;
            this.special_id = res.data[0].special_id;
            if (this.selectindex == 1) {
              this.catalogue(this.special_id);
            } else if (this.selectindex == 2) {
              this.sjlist(this.special_id);
            } else if (this.selectindex == 3) {
              this.exam(this.special_id);
            }
          } else {
            this.selectindex = 4;
            this.special_id = "";
            this.weakspotlist();
          }
        }
      });
    },
    // 预测分趋势
    drawLine(data) {
      //初始化echarts实例
      let myChart = echarts.init(document.getElementById("MathAbilitydivycf"));
      // 绘制图表
      myChart.setOption({
        grid: {
          left: "66",
          right: "24",
          bottom: "30",
          top: "50",
        },
        xAxis: {
          type: "category",
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#888",
              fontSize: "14",
            },
          },
        },
        series: [
          {
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#FF2A2A",
                  lineStyle: {
                    color: "#FF2A2A",
                  },
                  width: 3,
                  shadowColor: "rgba(184,0,0,0.25)",
                  shadowBlur: 6,
                  shadowOffsetY: 3,
                },
              },
            },
            data: data,
            type: "line",
            smooth: true,
            symbol: "none",
          },
        ],
      });
    },
    //评估报告
    datareport() {
      let data = {
        column_id: sessionStorage.getItem("column_id"),
      };
      datareport(data).then((res) => {
        if (res.code == 0) {
          this.bgobj = res.data;
          this.list = res.data.reportdata;
          this.drawLine(res.data.numdata);
        }
      });
    },
    praticeConfloulqh(data) {
      this.selectindex = data.area_type;
      this.special_id = data.special_id;
      if (this.selectindex == 1) {
        this.catalogue(this.special_id);
      } else if (this.selectindex == 2) {
        this.sjpage = 1;
        this.sjlistlb = [];
        this.sjlist(this.special_id);
      } else if (this.selectindex == 3) {
        this.mkpage = 1;
        this.mklistlb = [];
        this.exam(this.special_id);
      } else if (this.selectindex == 4) {
        this.rxpage = 1;
        this.rxlistlb = [];
        this.weakspotlist();
      } else if (this.selectindex == 5) {
        this.datareport();
      }
    },
    firstClick(data) {
      if (data.showFlag != undefined) {
        data.showFlag = !data.showFlag;
      }
    },
    secondClick(data) {
      if (data.showFlagChild != undefined) {
        data.showFlagChild = !data.showFlagChild;
      }
    },
    //获取试题-考点组题
    getList(data) {
      let params = {
        special_id: data.special_id,
        column_id: sessionStorage.getItem("column_id"),
        catalogue_id: data.catalogue_id,
        num: sessionStorage.getItem("num"),
        smodel: sessionStorage.getItem("smodel"),
        answer_id: data.answer_id,
      };
      sample(params).then((res) => {
        if (res.code == 0) {
          if (res.data.Question_list.length > 0) {
            if(res.data.Smodel!=sessionStorage.getItem("smodel")){
              if(data.question_count!=0){
                if (res.data.Smodel == 1) {
                  this.$router.push({
                    path: "/pape_do",
                    query: {
                      answer_id: data.answer_id,
                      special_id: data.special_id,
                      catalogue_id: data.catalogue_id,
                      area_type: this.selectindex,
                    },
                  });
                } else {
                  this.$router.push({
                    path: "/pape_doRecite",
                    query: {
                      answer_id: data.answer_id,
                      special_id: data.special_id,
                      catalogue_id: data.catalogue_id,
                      area_type: this.selectindex,
                      // bttype: 1,
                    },
                  });
                }
              }else{
                this.$message.error('缺少试题')
              }
            }else {
              if(data.question_count!=0){
                if (sessionStorage.getItem("smodel") == 1) {
                  this.$router.push({
                    path: "/pape_do",
                    query: {
                      answer_id: data.answer_id,
                      special_id: data.special_id,
                      catalogue_id: data.catalogue_id,
                      area_type: this.selectindex,
                    },
                  });
                } else {
                  this.$router.push({
                    path: "/pape_doRecite",
                    query: {
                      answer_id: data.answer_id,
                      special_id: data.special_id,
                      catalogue_id: data.catalogue_id,
                      area_type: this.selectindex,
                      // bttype: 1,
                    },
                  });
                }
              }else{
                this.$message.error('缺少试题')
              }
            }
          } else {
            this.$message.error("暂无试题");
          }
        }
      });
    },
    toZuoye(data) {
      console.log(data)
      if(data.answer_id>0){
         this.getList(data)
      }else {
        if(data.question_count!=0){
          if (sessionStorage.getItem("smodel") == 1) {
            this.$router.push({
              path: "/pape_do",
              query: {
                answer_id: data.answer_id,
                special_id: data.special_id,
                catalogue_id: data.catalogue_id,
                area_type: this.selectindex,
              },
            });
          } else {
            this.$router.push({
              path: "/pape_doRecite",
              query: {
                answer_id: data.answer_id,
                special_id: data.special_id,
                catalogue_id: data.catalogue_id,
                area_type: this.selectindex,
                // bttype: 1,
              },
            });
          }
        }else{
          this.$message.error('缺少试题')
        }
      }
    },
    tozd(data) {
      if (data.answer_staus == 0) {
        this.$router.push({
          path: "/pape_do",
          query: {
            answer_id: data.answer_id,
            paper_id: data.paper_id,
            special_id:data.special_id,
            area_type: this.selectindex,
          },
        });
      } else {
        this.$router.push({
          path: "/paper_report",
          query: {
            answer_id: data.answer_id,
            paper_id: data.paper_id,
            special_id:data.special_id,
            area_type: this.selectindex,
          },
        });
      }
    },
    zdySt() {
      this.$emit("zdyStShow");
    },
    jumpType(index) {
      if (index == 0) {
        this.$router.push({
          path: "/error_question",
          meta: {
            type: 3,
            name: "我的错题",
          },
        });
      }
      if (index == 1) {
        this.$router.push({
          path: "/collect_question",
          meta: {
            type: 3,
            name: "试题收藏",
          },
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/collect_paper",
          meta: {
            type: 3,
            name: "试卷收藏",
          },
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/test_history",
          meta: {
            type: 3,
            name: "练习历史",
          },
        });
      }
    },
    toHistory(data) {
      if (data.status == 1) {
        this.$router.push({
          path: "/paper_report",
          query: {
            answer_id: data.answer_id,
            paper_id: data.paper_id,
            area_type: this.selectindex,
          },
        });
      } else {
        this.$router.push({
          path: "/pape_do",
          query: {
            answer_id: data.answer_id,
            paper_id: data.paper_id,
            area_type: 2,
          },
        });
      }
    },
  },
};

</script>

<style scoped lang="scss">
@import "../assets/tiku.scss";
</style>
