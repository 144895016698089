<template>
  <div class="praticeHome">
    <div v-if="listshow.length==0" style="width: 100%; height: 400px; text-align: center; padding-top: 300px;background:#fff;">
        <img src="./assets/images/zanwu.png">
    </div>
    <div class="praticeHomchild main" v-else>
      <div class="crumbs" @click="crumbsClick()">
        <span>{{ crumbsname }}</span>
        <i class="iconfont icon-lesson_detail_down"></i>
      </div>
      <praticeCon @zdyStShow="zdyStShow"/>
      <!--自定义刷题-->
      <praticeHomeBrushQuestions ref="praticeHomeBrushQuestions" />



    </div>
    <praticeHomekemu   ref="praticeHomekemu" />
<!--    &lt;!&ndash;自定义刷题&ndash;&gt;-->
<!--    <praticeHomeBrushQuestions ref="praticeHomeBrushQuestions" />-->
<!--    &lt;!&ndash;选专业&ndash;&gt;-->
<!--    <praticeHomekemu ref="praticeHomekemu" />-->

  </div>
</template>

<script>
import praticeHomeBrushQuestions from "./compnents/praticeHomeBrushQuestions";
import praticeHomekemu from "./compnents/praticeHomekemu";
import praticeCon from "./compnents/praticeCon";
export default {
  name: "praticeHome",
  components: { praticeHomeBrushQuestions, praticeHomekemu, praticeCon },
  data() {
    return {
      crumbsname: "",
      listshow:[],
    };
  },
  mounted() {
    if (sessionStorage.getItem("str_column")) {
      this.$refs.praticeHomekemu.centerDialogVisible1 = false;
      this.crumbsname = sessionStorage.getItem("str_column");
    }
  },
  methods: {
    crumbsClick() {
      this.$refs.praticeHomekemu.centerDialogVisible1 = true;
    },
    zdyStShow() {
      this.$refs.praticeHomeBrushQuestions.centerDialogVisible = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./assets/tiku.scss";
</style>
