<template>
  <div class="praticeHomeBrushQuestions">
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="700px"
      :show-close="false"
    >
      <template slot="title">
        <div class="titleWrap">
          自定义刷题 <span>上次未完成题组不受此设置影响</span>
        </div>
      </template>
      <div class="praticeHomeBrushQuestionsbody">
        <dl class="home_customtcdivdl1 clearfix">
          <dt class="fontSize14 lineH20">设置刷题模式</dt>
          <dd class="clearfix">
            <div
              v-for="(item, index) in setModelist"
              :key="index"
              :class="{
                on: setMode == index + 1,
              }"
              @click="setModeclick(item)"
            >
              <h6 class="fontSize16 lineH48">{{ item.name }}</h6>
              <p>{{ item.dec }}</p>
            </div>
          </dd>
        </dl>
        <dl class="home_customtcdivdl2 clearfix">
          <dt class="fontSize16 lineH20">设置练习题目数量</dt>
          <dd>
            <span
              v-for="(item, index) in setNumberlist"
              :key="index"
              :class="setNumber == index ? 'on' : ''"
              @click="setNumberclick(item)"
              class="fontSize16 lineH48"
            >
              <i class="iconfont icon-pitchOn fontSize16"></i>
              {{ item.num }}
            </span>
          </dd>
        </dl>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "praticeHomeBrushQuestions",
  data() {
    return {
      centerDialogVisible: false,
      setModelist: [
        { id: 1, name: "做题模式", dec: "交卷查看答案" },
        { id: 2, name: "背题模式", dec: "做一题看一题答案" },
      ],
      setMode: 1,
      setNumberlist: [
        { id: 0, num: "5" },
        { id: 1, num: "10" },
        { id: 2, num: "15" },
        { id: 3, num: "20" },
        { id: 4, num: "25" },
        { id: 5, num: "30" },
        { id: 6, num: "35" },
      ],
      setNumber: 2,
      smodel: 1,
      num: 0,
    };
  },
  mounted() {
    if (sessionStorage.getItem("smodel") != null) {
      this.setMode = this.smodel = sessionStorage.getItem("smodel");
    } else {
      sessionStorage.setItem("smodel", this.smodel);
    }
    if (sessionStorage.getItem("num") != null) {
      this.num = sessionStorage.getItem("num");
      this.setNumberlist.forEach((item, index) => {
        if (this.num == item.num) {
          this.setNumber = index;
        }
      });
    } else {
      sessionStorage.setItem("num", this.setNumberlist[this.setNumber].num);
    }
  },
  methods: {
    //练习模式
    setModeclick(data) {
      this.setMode = data.id;
      this.smodel = data.id;
    },
    //练习数量
    setNumberclick(data) {
      this.setNumber = data.id;
      this.num = data.num;
    },
    submit(){
      sessionStorage.setItem("smodel", this.smodel);
      sessionStorage.setItem("num", this.num);
      this.centerDialogVisible = false;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../assets/tiku";
</style>
